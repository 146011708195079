<template>
  <div>
    <div class="text-right mb-2 col-10 offset-1">
      <CButton color="primary" to="/tfw/partner/profile">Back</CButton>
    </div>
    <component v-if="partnerInfo" :is="partnerInfo.component" />
  </div>
</template>
<script>
import SLCProfile from "@/components/reusable/ProfilePage/SLCPage";
import DOHProfile from "@/components/reusable/ProfilePage/DOHPage";
import NHSProfile from "@/components/reusable/ProfilePage/NHSPage";

import { CONSTANT } from "@/helpers/helper";
import Vue from "vue";
Vue.component("SLCProfile", SLCProfile);
Vue.component("DOHProfile", DOHProfile);
Vue.component("NHSProfile", NHSProfile);
export default {
  data() {
    return {
      partnerInfo: null,
    };
  },
  methods: {
    updateProfilePage() {
      const { id } = this.$route.params;
      const partners = CONSTANT.partners;
      this.partnerInfo = partners.find((v) => v.partner_id == id);
    },
  },
  created() {
    this.updateProfilePage();
  },
  watch: {
    "$route.params": function () {
      this.updateProfilePage();
    },
  },
};
</script>
